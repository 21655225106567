import type { Menu, MenuItem, SubMenuItem } from "@/cms/schema";
import { motion } from "framer-motion";
import React, { PropsWithChildren } from "react";
import { AnimatedLink } from "./animatedLink";
import PaddedSection from "./paddedSection";

interface Props {
  menu: Menu;
  socials: React.ReactNode;
  onItemClick: () => void;
}

export default function Navigation({ menu, socials, onItemClick }: Props) {
  const navigationVariants = {
    open: { y: 0 },
    closed: { y: "-100%" },
  };

  const menuVariants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.3,
        duration: 0.35,
        ease: "easeIn",
      },
    },
    closed: {
      y: -5,
      opacity: 0,
      transition: {
        duration: 0.2,
        ease: "easeIn",
      },
    },
  };

  const menuItems = menu.items.map((item) => {
    return <MenuItem key={item.id} item={item} onItemClick={onItemClick} />;
  });

  return (
    <motion.nav
      key="navigation"
      initial={false}
      variants={navigationVariants}
      transition={{ duration: 0.5, ease: "circInOut" }}
      className="absolute inset-0 z-20 flex flex-col bg-blue"
    >
      <motion.div variants={menuVariants} className="h-full">
        <PaddedSection headerPadding className="flex h-full flex-col">
          <Menu>{menuItems}</Menu>
          {socials}
        </PaddedSection>
      </motion.div>
    </motion.nav>
  );
}

function Menu({ children }: PropsWithChildren) {
  return <div className="mt-10 flex flex-1 flex-col gap-8">{children}</div>;
}
function SubMenu({ children }: PropsWithChildren) {
  return (
    <div className="flex w-full flex-wrap gap-2 text-blue-200 lg:gap-5">
      {children}
    </div>
  );
}

function MenuItem({
  item,
  onItemClick,
}: {
  item: MenuItem;
  onItemClick: () => void;
}) {
  const subMenuitems = item.submenu?.map((subitem) => {
    return (
      <SubMenuitem key={subitem.id} item={subitem} onItemClick={onItemClick} />
    );
  });

  return (
    <div className="flex flex-col">
      <div className="font-semibold text-blue-300 lg:text-2xl">
        {item.label}
      </div>
      <SubMenu>{subMenuitems}</SubMenu>
    </div>
  );
}

function SubMenuitem({
  item,
  onItemClick,
}: {
  item: SubMenuItem;
  onItemClick: () => void;
}) {
  return (
    <AnimatedLink
      className="text-lg font-bold lg:text-3xl"
      href={item.url}
      text={item.label}
      onClick={onItemClick}
    />
  );
}
