"use client";

import type { Menu } from "@/cms/schema";
import clsx from "clsx";
import { AnimatePresence, motion, useCycle } from "framer-motion";
import { usePathname, useRouter } from "next/navigation";
import NextTopLoader from "nextjs-toploader";
import { PropsWithChildren, useEffect, useState } from "react";
import CookieBanner from "./_components/cookieBanner";
import Footer from "./_components/footer";
import Header from "./_components/header";
import Navigation from "./_components/navigation";
import avenir from "./fonts/avenir";
import { blue } from "./_utils/tailwind";

interface Props {
  menus: Menu[];
  serverComponents: {
    socials: React.ReactNode;
    newsletter: React.ReactNode;
  };
  redirects: Map<string, string>;
}

export function LayoutClient({
  menus,
  serverComponents,
  redirects,
  children,
}: PropsWithChildren<Props>) {
  /* avenir.className applies the CDTM font Avenir to all HTML elements,
   * avenir.variable sets the Avenir CSS variable, which is mapped to `font-sans`
   * in the Tailwind config file */
  const avenirConfig = [avenir.className, avenir.variable];

  const [isMenuOpen, toggleMenu] = useCycle(false, true);
  const [hideCookieBanner, setHideCookieBanner] = useState(true);

  useEffect(() => {
    const userAsked = localStorage.getItem("cookie_consent") !== null;
    setHideCookieBanner(userAsked);
  }, []);

  const headerMenu = menus.find((menu) => menu.name === "Header")!;
  const footerMenu = menus.find((menu) => menu.name === "Footer")!;

  const pathname = usePathname();
  const router = useRouter();

  useEffect(() => {
    if (redirects.has(pathname)) {
      const to = redirects.get(pathname)!;
      router.replace(to);
    }
  }, [redirects, pathname, router]);

  if (redirects.has(pathname)) {
    return <body className="bg-black" />;
  }

  return (
    <motion.body
      initial={false}
      animate={isMenuOpen ? "open" : "closed"}
      className={clsx(
        "grid min-h-svh grid-rows-[auto,1fr,auto] bg-black text-base text-white",
        avenirConfig,
        isMenuOpen && "overflow-hidden",
      )}
    >
      <div>
        <NextTopLoader showSpinner={false} color={blue.DEFAULT} />
        <Header isOpen={isMenuOpen} toggle={toggleMenu} />
        <Navigation
          menu={headerMenu}
          socials={serverComponents.socials}
          onItemClick={toggleMenu}
        />
      </div>

      {children}

      <AnimatePresence>
        {!hideCookieBanner && (
          <CookieBanner onHideCookieBanner={() => setHideCookieBanner(true)} />
        )}
      </AnimatePresence>

      <Footer
        onShowCookieSettings={() => setHideCookieBanner(false)}
        serverComponents={serverComponents}
        menu={footerMenu}
      />
    </motion.body>
  );
}
