"use client";

import { addToNewsletter } from "@/app/_actions/newsletter";
import { InputHTMLAttributes } from "react";
import { useFormState, useFormStatus } from "react-dom";

export default function Newsletter() {
  const [state, formAction] = useFormState(addToNewsletter, undefined);
  return (
    <div className="flex w-full flex-col items-center">
      <div className="mb-2 text-lg font-semibold text-gray-300 md:text-center">
        Subscribe to our newsletter
      </div>
      <form
        className="flex w-full flex-col gap-2 lg:flex-row lg:justify-center"
        action={formAction}
      >
        <TextBox
          name="firstName"
          type="text"
          autoComplete="given-name"
          placeholder="First name"
          required
        />
        <TextBox
          name="lastName"
          type="text"
          autoComplete="family-name"
          placeholder="Last name"
          required
        />
        <TextBox
          name="email"
          type="email"
          autoComplete="email"
          placeholder="Email"
          required
        />
        <NewsletterSubmitButton state={state} />
      </form>
    </div>
  );
}

function TextBox({ ...props }: InputHTMLAttributes<HTMLInputElement>) {
  return (
    <input
      {...props}
      className="rounded-md border-2 border-gray-800 bg-gray-900 p-2 pl-4 autofill:bg-gray-900 focus:border-blue-600 focus:outline-none"
    />
  );
}

function NewsletterSubmitButton({
  state: successful,
}: {
  state: boolean | undefined;
}) {
  const { pending } = useFormStatus();

  let message: string;
  switch (successful) {
    case undefined:
      message = "Subscribe";
      break;
    case true:
      message = "Subscribed!";
      break;
    case false:
      message = "Subscribe failed. Please try again later.";
      break;
  }

  return (
    <button
      type="submit"
      className="button-gray"
      disabled={pending || successful}
    >
      {message}
    </button>
  );
}
