import clsx from "clsx";
import { HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLElement> {
  headerPadding?: boolean;
}

export default function PaddedSection({
  children,
  headerPadding = false,
  className,
  ...props
}: Props) {
  return (
    <section
      className={clsx(
        className,
        "px-4 pb-8 lg:px-12 lg:pb-14",
        headerPadding && "pt-24 lg:pt-28",
      )}
      {...props}
    >
      {children}
    </section>
  );
}
