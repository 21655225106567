import { motion } from "framer-motion";
import { blue } from "../_utils/tailwind";

interface Props {
  className?: string;
}

const nameVariants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: -10 },
};

export const logoVariants = {
  open: { color: blue[200] },
  closed: { color: "#E4E4E7", transition: { delay: 0.3 } },
} as const;

const Logo = ({ className }: Props) => {
  return (
    <div className="flex flex-row items-center gap-3">
      <motion.svg
        viewBox="0 0 85 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        variants={logoVariants}
        className={className}
      >
        <g clipPath="url(#a)">
          <path
            d="M0 64V0h58.03v15.46h-3.71V3.71H3.71v56.33l50.61-3.22V46.46h3.71V60.3L0 64Z"
            fill="currentColor"
          />
          <path
            d="M48.37 25.58h5.58v14.84h4.47V25.58h5.63V21.4H48.37v4.18Zm32.3-4.16-5.07 6.32-5.11-6.32h-3.83V40.4h4.5V29.15L75 33.83h1.2l3.76-4.65V40.4h4.52V21.42h-3.81Zm-42.39-.02h-7.63v19.01h7.25c4.86 0 10.02-3.33 10.02-9.51.01-6.56-4.84-9.5-9.64-9.5Zm-3.15 4.18h2.21c4.94 0 5.97 2.9 5.97 5.33 0 4.64-4.07 5.33-6.5 5.33h-1.68V25.58Zm-10.49 9.14c-.97 1.24-2.11 1.87-3.4 1.87-3.13 0-5.32-2.4-5.32-5.85 0-3.24 2.24-5.6 5.32-5.6 1.23 0 2.18.46 3.19 1.52l.4.43 3.46-2.55-.46-.54c-1.76-2.05-3.86-3-6.61-3-5.81 0-9.88 4.09-9.88 9.95 0 5.77 4.06 9.8 9.88 9.8 3 0 5.43-1.17 7.04-3.39l.39-.54-3.62-2.59-.39.49Z"
            fill="currentColor"
          />
        </g>
        <defs>
          <clipPath id="a">
            <path fill="currentColor" d="M0 0h84.48v64H0z" />
          </clipPath>
        </defs>
      </motion.svg>
      <motion.div
        variants={nameVariants}
        transition={{ duration: 0.5 }}
        className="select-none text-sm font-semibold leading-4 text-blue-200"
      >
        Center for
        <br />
        Digital Technology
        <br />
        and Management
      </motion.div>
    </div>
  );
};

export default Logo;
