import { SVGMotionProps, motion } from "framer-motion";
import { logoVariants } from "./logo";

interface Props {
  toggle: () => void;
}

export default function MenuToggle({ toggle }: Props) {
  return (
    <button onClick={toggle}>
      <motion.svg
        width="23"
        height="23"
        viewBox="0 0 23 23"
        variants={logoVariants}
      >
        <Path
          variants={{
            closed: { d: "M 2 2.5 L 20 2.5" },
            open: { d: "M 3 16.5 L 17 2.5" },
          }}
        />
        <Path
          d="M 2 9.423 L 20 9.423"
          variants={{
            closed: { opacity: 1 },
            open: { opacity: 0 },
          }}
          transition={{ duration: 0.1 }}
        />
        <Path
          variants={{
            closed: { d: "M 2 16.346 L 20 16.346" },
            open: { d: "M 3 2.5 L 17 16.346" },
          }}
        />
      </motion.svg>
    </button>
  );
}
function Path(props: SVGMotionProps<SVGPathElement>) {
  return (
    <motion.path
      initial={false}
      fill="transparent"
      strokeWidth="3"
      stroke="currentColor"
      strokeLinecap="round"
      {...props}
    />
  );
}
