import { motion } from "framer-motion";

interface Props {
  onHideCookieBanner: () => void;
}

export default function CookieBanner({ onHideCookieBanner }: Props) {
  const handleAcceptCookies = () => {
    setConsent(true);
    onHideCookieBanner();
  };

  const handleDeclineCookies = () => {
    setConsent(false);
    onHideCookieBanner();
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, ease: "easeOut" }}
      exit={{ opacity: 0, y: "100%" }}
      className="fixed bottom-4 right-4 z-40 max-w-72 rounded-md bg-blue-900 p-4"
    >
      <p className="mb-4">
        Do you allow us to use tracking cookies to understand how you use the
        site?
      </p>
      <div className="flex flex-row justify-evenly gap-2 font-semibold text-black">
        <button
          type="button"
          className="flex-1 rounded-lg bg-blue-100 py-2 transition duration-300 hover:brightness-75"
          onClick={handleDeclineCookies}
        >
          Decline
        </button>
        <button
          type="button"
          className="flex-1 rounded-lg bg-blue-100 py-2 transition duration-300 hover:brightness-75"
          onClick={handleAcceptCookies}
        >
          Allow
        </button>
      </div>
    </motion.div>
  );
}

declare function createConsentMode(analytics: boolean): Gtag.ConsentParams;
function setConsent(analytics: boolean) {
  const consentMode = createConsentMode(analytics);
  localStorage.setItem("cookie_consent", JSON.stringify(consentMode));
  gtag("consent", "update", consentMode);
  // remove cookies starting with _ga if analytics is disabled
  if (!analytics) {
    const cookies = document.cookie.split("; ");
    for (const cookie of cookies) {
      const [name] = cookie.split("=");
      if (name.startsWith("_ga")) {
        deleteCookie(name);
      }
    }
  }
}

function deleteCookie(name: string) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}
