import Link from "next/link";
import Logo from "./logo";
import MenuToggle from "./menuToggle";

interface Props {
  isOpen: boolean;
  toggle: () => void;
}

export default function Header({ isOpen, toggle }: Props) {
  return (
    <header className="absolute inset-x-0 top-0 z-50 flex items-center justify-between px-6 py-5">
      <Link href="/" onClick={() => isOpen && toggle()}>
        <Logo className="h-11" />
      </Link>
      <MenuToggle toggle={toggle} />
    </header>
  );
}
