import localFont from "next/font/local";

export const avenir = localFont({
  src: [
    {
      path: "./Avenir-Roman.ttf",
      style: "normal",
      weight: "400",
    },
    {
      path: "./Avenir-Heavy.ttf",
      style: "normal",
      weight: "600",
    },
    {
      path: "./Avenir-Black.ttf",
      style: "normal",
      weight: "700",
    },
    {
      path: "./Avenir-Oblique.ttf",
      style: "oblique",
      weight: "400",
    },
    {
      path: "./Avenir-HeavyOblique.ttf",
      style: "oblique",
      weight: "600",
    },
    {
      path: "./Avenir-BlackOblique.ttf",
      style: "oblique",
      weight: "700",
    },
  ],
  display: "block",
  /* CSS variable */
  variable: "--font-avenir",
});

export default avenir;
